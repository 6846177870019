export const container = {
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
};
export const containerDefault = {
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column'
};
export const containerMessage = () => ({
  backgroundColor: '#1E1E1E',
  border: '1px solid #A547FF',
  borderRadius: '16px 16px 0px 16px',
  color: '#FFFFFF',
  padding: 1,
  marginTop: 1,
  minWidth: 180,
  maxWidth: 400,
  width: '100%',
  position: 'relative'
});
export const messageAfter = {
  position: 'absolute',
  clip: 'rect(auto, 40px, auto, 20px)',
  transform: 'rotate(-320deg)',
  bottom: -0,
  right: -10,
  width: '32px',
  height: '32px',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-15px',
    bottom: '-40px',
    left: '-15px',
    right: '3px',
    background: '#1E1E1E',
    transform: 'rotate(-45deg)',
    border: '1px solid #A547FF'
  }
};
export const boxContainer = () => ({
  width: '100%'
});
export const inputStyle = () => ({
  '& .MuiFormHelperText-root': {
    fontSise: '100px !important',
    color: '#FFF'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 4,
    backgroundColor: '#3B3442',
    borderColor: '#A547FF',
    borderWidth: 2
  },
  '& .MuiOutlinedInput-input': {
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 20
  },
  '& .MuiInputAdornment-root p': {
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 20
  }
});
export const messageBubbleContainer = (author: string) => ({
  minWidth: '100%',
  maxWidth: '100%',
  display: 'flex',
  marginRight: 20,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: author === 'us' ? 'flex-start' : 'flex-end'
});
