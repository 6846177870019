import ModalHelp from '../ModalHelp';
import ModalHelpCPF from '../ModalHelpCPF';
import ModalHelpValueAccessories from '../ModalHelpValueAccessories';
import ModalHelpValueEquipment from '../ModalHelpValueEquipment';
const MESSAGES = [
  {
    id: 0,
    content: 'Olá, eu sou a Ari ;)',
    author: 'us',
    bold: true
  },
  {
    id: 1,
    content: `Vou te ajudar a encontrar as melhores opções de seguro,
    monitoramento e assistência 24h para você e seu equipamento.`,
    author: 'us'
  },
  {
    id: 2,
    content: 'Qual seu nome?',
    author: 'us'
  },
  {
    id: 3,
    type: 'inputName',
    author: 'us'
  },
  {
    id: 4,
    content: 'Oi {1}, que bom te conhecer!!!\n Agora me fale seu email?',
    author: 'us'
  },
  {
    id: 5,
    type: 'inputEmail',
    author: 'us'
  },
  {
    id: 6,
    content: 'Qual é o seu CPF?',
    author: 'us',
    QuestionComponent: () => (
      <>
        <ModalHelpCPF />
      </>
    )
  },
  {
    id: 7,
    type: 'inputCpf',
    author: 'us'
  },
  {
    id: 8,
    content: 'Qual é o seu gênero?',
    author: 'us'
  },
  {
    id: 9,
    type: 'selectGender',
    author: 'us'
  },
  {
    id: 10,
    content: 'Fale um pouco sobre o seu equipamento...',
    author: 'us'
  },
  {
    id: 11,
    content: 'Para qual o tipo de equipamento você quer fazer o seguro?',
    author: 'us',
    bold: true,
    QuestionComponent: () => (
      <>
        <ModalHelp />
      </>
    )
  },
  {
    id: 12,
    content: 'Escolha um equipamento',
    author: 'them',
    type: 'equipment'
  },
  {
    id: 13,
    content: `Qual é o seu CEP (pernoite do equipamento)?`,
    author: 'us'
  },
  {
    id: 14,
    content: `Qual é o seu CEP (pernoite do equipamento)?`,
    author: 'us'
  },
  {
    id: 15,
    content: `Insira seu CEP`,
    author: 'them',
    type: 'zipcode'
  },
  {
    id: 16,
    content: `Qual é o valor do seu equipamento?`,
    author: 'us',
    bold: true,
    QuestionComponent: () => (
      <>
        <ModalHelpValueEquipment />
      </>
    )
  },
  {
    id: 17,
    content: `Insira o valor do equipamento`,
    author: 'them',
    type: 'equipmentValue'
  },
  {
    id: 18,
    content: `Qual o ano de fabricação do seu equipamento?`,
    author: 'us',
    bold: true
  },
  {
    id: 19,
    content: `Qual o ano de fabricação do seu equipamento?`,
    author: 'them',
    type: 'yearOfManufacture'
  },
  {
    id: 20,
    content: `Caso tenha acessórios e queira assegurar. Qual é o valor total do(s) acessório(s)?`,
    author: 'us',
    bold: true,
    QuestionComponent: () => (
      <>
        <ModalHelpValueAccessories />
      </>
    )
  },
  {
    id: 21,
    content: `Insira o valor dos acessórios`,
    author: 'them',
    type: 'accessoriesValue'
  },
  {
    id: 22,
    content: `Qual é o material do quadro do seu equipamento?`,
    author: 'us',
    bold: true
  },
  {
    id: 23,
    content: `Insira o material do equipamento`,
    author: 'them',
    type: 'equipmentMaterial'
  },
  {
    id: 24,
    content: '{name}, você participa de competições?',
    author: 'us'
  },
  {
    id: 25,
    content: ``,
    author: 'them',
    type: 'participatesInCompetitions'
  },
  {
    id: 26,
    content: '{name}, deseja incluir cobertura internacional?',
    author: 'us'
  },
  {
    id: 27,
    content: '',
    author: 'them',
    type: 'internationalCoverage'
  },
  {
    id: 28,
    content: 'Quais continentes?',
    author: 'us'
  },
  {
    id: 29,
    content: ``,
    author: 'them',
    type: 'whichContinents'
  },
  {
    id: 30,
    content: `OBS: Atualmente só aceitamos equipamento original, sem nenhum tipo de personalização.`,
    author: 'us',
    bold: true
  },
  {
    id: 31,
    content: `Insira a configuração`,
    author: 'them',
    type: 'equipmentConditions'
  },
  {
    id: 32,
    content: 'Durante a sua viagem ao exterior, onde a sua bicicleta ficará guardada?',
    author: 'us'
  },
  {
    id: 33,
    content: ``,
    author: 'them',
    type: 'placeStore'
  }
];

export default MESSAGES;
