import * as yup from 'yup';

export default yup
  .object({
    number: yup
      .string()
      .required('Número do cartão é obrigatório')
      .test(`test-card-number-length`, 'Digite o número completo, este parece incompleto.', (value) => {
        if (value) {
          const newValue = value.replace(/\s/g, '');
          if (newValue.length > 15) return true;
        }
        return false;
      }),
    cvv: yup
      .string()
      .min(3, '3 a 5 dígitos')
      .required('Digite um CVV válido (3 a 5 dígitos).')
      .test('test-cvv-length', 'Digite um CVV válido (3 a 5 dígitos).', (value) => {
        if (value) {
          if (value?.length < 3) {
            return false;
          }
        }
        return true;
      }),
    expiry: yup
      .string()
      .required('Data de expiração do cartão é obrigatório')
      .test(`test-expiry-length`, 'Digite uma data válida (mês/ano).', (value) => {
        if (value) {
          const date = new Date().getFullYear().toString().substring(-2);
          const month = parseInt(value.substring(0, 2));
          const year = parseInt(value.substring(3, 5));
          if (month > 12) {
            return false;
          }
          const newYear = parseInt(date) - 2000;
          if (year < newYear) {
            return false;
          }
        }
        return true;
      }),
    name: yup
      .string()
      .required('Nome no cartão é obrigatório')
      .test(`test-name-complete-length`, 'Digite o nome completo, este parece incompleto.', (value) => {
        if (value && value.split(' ').length > 1) return true;
        return false;
      })
  })
  .required();
