import { useState, useEffect } from 'react';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { headerContainer, container, containerButton, containerForm } from './styles';
import Input from '~/components/Input';
import { useNavigate } from 'react-router-dom';
import schema from './validation';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStore, { storeState } from '~/store';
import { ContractPostAddress } from '~/services/modules/contract/types';
import { searchAddressByZipcode } from '~/utils/searchAddressByZipcode';
import { CEP } from 'cep-promise';

export default function HelpZipcodePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [useBuyerData, setUseBuyerData] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors, dirtyFields }
  } = useForm<ContractPostAddress>({
    resolver: yupResolver(schema),
    defaultValues: {
      additional_details: '',
      city: '',
      country: '',
      neighborhood: '',
      number: '',
      state: '',
      street: '',
      zipcode: ''
    }
  });

  const {
    contract_id,
    contractAsyncPostAddress,
    setZipcode,
    zipcode,
    city,
    state,
    neighborhood,
    street,
    number,
    additional_details,
    insured,
    cameFromEmail
  } = useStore((store) => ({
    contractAsyncPostAddress: store.contractAsyncPostAddress,
    contract_id: store.contract_id,
    setZipcode: store.setZipcode,
    zipcode: store.quote.zipcode,
    city: store.quote.city,
    state: store.quote.state,
    neighborhood: store.quote.neighborhood,
    street: store.quote.street,
    number: store.quote.number,
    additional_details: store.quote.additionalDetails,
    insured: store.insured,
    cameFromEmail: store.cameFromEmail
  }));

  const handleChangeUseBuyerData = () => {
    const newState = !useBuyerData;

    if (newState && cameFromEmail) {
      const { address } = insured;
      setValue('city', address.city);
      setValue('state', address.state);
      setValue('neighborhood', address.neighborhood);
      setValue('street', address.street);
      setValue('zipcode', address.zipcode);
      setValue('number', address.number);
      setValue('additional_details', address.additional_details);
      setUseBuyerData(newState);
      return;
    }

    if (newState && !zipcode) {
      storeState.addToast({
        title: 'Não foi possível encontrar os Dados do Comprador.',
        message: '',
        type: 'warning'
      });
      return;
    }

    if (newState) {
      setValue('zipcode', zipcode);
      setValue('city', city);
      setValue('state', state);
      setValue('neighborhood', neighborhood);
      setValue('street', street);
      setValue('number', number);
      setValue('additional_details', additional_details);

      setUseBuyerData(newState);
      return;
    }

    setUseBuyerData(newState);
    reset();
  };

  const handleChangeZipCode = (value: string) => {
    const cleanZipCode = value.replaceAll('-', '').trim();

    const onSuccess = (params: CEP) => {
      setValue('city', params.city);
      setValue('state', params.state);
      setValue('neighborhood', params.neighborhood);
      setValue('street', params.street);
      setValue('zipcode', params.cep);
    };

    const onError = () => {
      storeState.addToast({
        title: 'Não foi possível encontrar o CEP',
        message: 'O CEP indicado não  foi encontrado',
        type: 'warning'
      });
    };

    if (cleanZipCode.length === 8) {
      searchAddressByZipcode(value, onSuccess, onError);
    }
  };

  const onSubmit: SubmitHandler<ContractPostAddress> = async (data) => {
    if (contract_id) {
      setLoading(true);
      try {
        await contractAsyncPostAddress(contract_id, {
          ...data,
          country: 'Brasil',
          number: String(data.number)
        });
        setLoading(false);
        setZipcode(getValues('zipcode'));
        navigate('/contract/equipment');
      } catch (error: any) {
        setLoading(false);
        let errorMessage =
          'Não foi possível prosseguir a sua assinatura no momento. Por favor, tente novamente mais tarde.';
        if (error.isAxiosError) {
          const notifications = error.response?.data?.notifications;
          if (notifications) errorMessage = notifications[0]?.message;
        }
        storeState.addToast({
          title: 'Error',
          message: errorMessage,
          type: 'error'
        });
      }
    } else {
      storeState.addToast({
        title: 'Não foi possível recuperar o contrato.',
        message: '',
        type: 'warning'
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Box sx={headerContainer}>
                <Typography variant="h6" align="center">
                  Dados para contrato
                </Typography>
              </Box>
              <Divider variant="middle" />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={containerForm}>
                  <Typography sx={{ fontSize: { xs: 16, sm: 20 } }} variant="h6" fontWeight="bold">
                    2 - Endereço completo (pernoite)
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        marginTop: 1
                      }}
                      control={<Checkbox checked={useBuyerData} onChange={handleChangeUseBuyerData} color="success" />}
                      label={
                        <Typography component={'span'} sx={{ fontSize: { xs: 14, sm: 16 } }}>
                          Utilizar as mesmas informações do “Dados de Comprador”
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <Controller
                    name="zipcode"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="CEP"
                          placeholder="XXXXX-XXX"
                          mask="zipcode"
                          error={errors?.zipcode ? true : false}
                          isDirty={dirtyFields.zipcode}
                          helperText={errors?.zipcode?.message}
                          {...field}
                          onChange={(event) => {
                            handleChangeZipCode(event.target.value);
                            field.onChange(event);
                          }}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Estado/UF (sigla)"
                          placeholder={'Digite primeiro o CEP'}
                          inputProps={{ maxLength: 2 }}
                          error={errors?.state ? true : false}
                          isDirty={dirtyFields.state}
                          helperText={errors?.state?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Cidade"
                          placeholder={'Digite primeiro o CEP'}
                          error={errors?.city ? true : false}
                          isDirty={dirtyFields.city}
                          helperText={errors?.city?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="neighborhood"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Bairro"
                          placeholder={'Digite primeiro o CEP'}
                          error={errors?.neighborhood ? true : false}
                          isDirty={dirtyFields.neighborhood}
                          helperText={errors?.neighborhood?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="street"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Endereço"
                          placeholder={'Digite primeiro o CEP'}
                          error={errors?.street ? true : false}
                          isDirty={dirtyFields.street}
                          helperText={errors?.street?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="number"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Número"
                              placeholder="XXX"
                              error={errors?.number ? true : false}
                              isDirty={dirtyFields.number}
                              helperText={errors?.number?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="additional_details"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Complemento"
                              placeholder="Apto ou bloco"
                              error={errors?.additional_details ? true : false}
                              isDirty={dirtyFields.additional_details}
                              helperText={errors?.additional_details?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={containerButton}>
                  <LoadingButton sx={{ fontSize: { xs: 16, sm: 20 } }} loading={loading} type="submit">
                    salvar e sair
                  </LoadingButton>
                  <LoadingButton
                    sx={{ fontSize: { xs: 16, sm: 20 } }}
                    loading={loading}
                    variant="contained"
                    type="submit"
                  >
                    Próximo passo
                  </LoadingButton>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <Card>
            <CardContent>
              <SidebarMesages showProduct={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
