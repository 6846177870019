import { Box, useMediaQuery } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Focused } from 'react-credit-cards-2';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import CardComponent from '~/pages/Payment/CardCredit';
import Input from '~/components/Input';

import { cardFormContainer, cardFormInputsContainer, headerContainer, inputValidtyFormContainer } from '../styles';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import rollbar from 'rollbar';
import { storeState } from '~/store';
import { CreateTokenCardResponse } from '../../renewal/interfaces/card-token-response';
import api from '~/services/api';
import { yupResolver } from '@hookform/resolvers/yup';

import schema from '../validation';
export type Inputs = {
  number: string;
  cvv: string;
  expiry: string;
  name: string;
  zipcode: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  numberAddress: string;
  additionalDetails: string;
};
type PagarmeResponseCardError = {
  message: string;
  errors: {
    'request.card': string[];
  };
};
interface CardAjustProps {
  contractCode: string;
  setSucessRequest: Dispatch<SetStateAction<boolean>>;
}

export function CardAjust({ contractCode, setSucessRequest }: CardAjustProps) {
  const showCard = useMediaQuery('(min-width:420px)');
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields }
  } = useForm<Inputs>({
    resolver: yupResolver(schema)
  });

  const [allIsEmpty, setAllIsEmpty] = useState(true);
  const [focused, setfocused] = useState<Focused>('number');
  const [loading, setLoading] = useState(false);
  const PAGARME_URL = process.env.REACT_APP_PAGARME_API_URL;
  const PAGARME_APP_ID = process.env.REACT_APP_PAGARME_APP_ID;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const expiryMonth = data.expiry.split('/')[0];
    const expiryYear = data.expiry.split('/')[1];

    const createTokenCardPayload = {
      type: 'card',
      card: {
        number: data.number.replaceAll(' ', ''),
        exp_month: parseInt(expiryMonth),
        exp_year: parseInt(expiryYear),
        holder_name: data.name,
        cvv: data.cvv.trimEnd()
      }
    };

    try {
      setLoading(true);
      const createTokenCardResponse = await axios.post<CreateTokenCardResponse>(
        `${PAGARME_URL}/tokens?appId=${PAGARME_APP_ID}`,
        {
          ...createTokenCardPayload
        }
      );

      api.patch(`/Contracts/${contractCode}/card`, {
        card_token: createTokenCardResponse.data.id
      });

      setSucessRequest(true);
    } catch (error: any) {
      setSucessRequest(false);

      if (error.isAxiosError) {
        const axiosError = error as AxiosError<PagarmeResponseCardError>;
        const errors = axiosError?.response?.data?.errors;
        const errorData = {
          message: axiosError.response?.data.message,
          erros: axiosError.response?.data.errors
        };

        switch (axiosError?.message) {
          case 'Network Error':
            storeState.addToast({
              title: 'Não foi possível realizar o pagamento',
              message: 'Parece que você está sem internet. Verifique suas configurações de internet e tente novamente.',
              type: 'error'
            });
            break;
          case 'Request failed with status code 422':
            if (errors?.['request.card']) {
              const errorMessages = axiosError?.response?.data?.errors['request.card'];
              const formattedMessage = `Erro: ${errorMessages?.join(', ')}`;
              storeState.addToast({
                title: 'Não foi possível realizar o pagamento',
                message: formattedMessage,
                type: 'error'
              });
            } else {
              rollbar.error('Erro ao efetuar pagamento', {
                message: axiosError.message,
                status: axiosError.response?.status,
                data: errorData
              });
              storeState.addToast({
                title: 'Não foi possível realizar o pagamento',
                message: 'Error: ' + errorData.message,
                type: 'error'
              });
            }
            break;
          default:
            rollbar.error('Erro ao efetuar pagamento', {
              message: axiosError.message,
              status: axiosError.response?.status,
              data: errorData
            });
            storeState.addToast({
              title: 'Não foi possível realizar o pagamento',
              message: 'Error: ' + axiosError.message,
              type: 'error'
            });
        }
      } else {
        storeState.addToast({
          title: 'Não foi possível realizar o pagamento',
          message:
            'Confira os dados e tente novamente. Caso o erro persista, favor entrar em contato com nosso atendimento passando mais informações. Error: ' +
            error,
          type: 'error'
        });

        rollbar.error('Erro ao efetuar pagamento', error);
        storeState.addToast({
          title: 'Não foi possível realizar o pagamento',
          message: 'Error: ' + error,
          type: 'error'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const watchedFields = watch();
  useEffect(() => {
    const isEmpty: boolean =
      !watchedFields.number || !watchedFields.cvv || !watchedFields.expiry || !watchedFields.name;
    setAllIsEmpty(!isEmpty);
  }, [watchedFields, errors]);
  return (
    <>
      <Box sx={cardFormContainer}>
        {showCard && (
          <Box sx={{ ...headerContainer, paddingBottom: '0' }}>
            <CardComponent control={control} focused={focused as Focused} />
          </Box>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={cardFormInputsContainer}>
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    label="Número do cartão"
                    onFocus={(e) => setfocused('number')}
                    placeholder="XXXX XXXX XXXX XXXX"
                    mask={'cardNumber'}
                    isDirty={dirtyFields.number}
                    error={errors?.number?.message ? true : false}
                    helperText={errors?.number?.message}
                    {...field}
                  />
                </>
              )}
            />
            <Controller
              name="cvv"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    onFocus={(e) => setfocused('cvc')}
                    label="CVV"
                    placeholder="XXX"
                    mask={'cvv'}
                    isDirty={dirtyFields.cvv}
                    error={errors?.cvv?.message ? true : false}
                    helperText={errors?.cvv?.message}
                    {...field}
                  />
                </>
              )}
            />
          </Box>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <>
                <Input
                  label="Nome no cartão"
                  onFocus={(e) => setfocused('number')}
                  placeholder="exatamente como está impresso"
                  isDirty={dirtyFields.name}
                  error={errors?.name?.message ? true : false}
                  helperText={errors?.name?.message}
                  {...field}
                />
              </>
            )}
          />
          <Box sx={inputValidtyFormContainer}>
            <Controller
              name="expiry"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    label="Expira"
                    onFocus={(e) => setfocused('number')}
                    placeholder="MM/AA"
                    mask={'expiredDate'}
                    // sx={{ width: '20rem' }}
                    error={errors?.expiry?.message ? true : false}
                    isDirty={dirtyFields.expiry}
                    helperText={errors?.expiry?.message}
                    {...field}
                  />
                </>
              )}
            />
          </Box>
          <Box sx={{ ...headerContainer, display: 'flex', paddingBottom: '0' }}>
            <LoadingButton
              variant="outlined"
              disabled={!allIsEmpty}
              loading={loading}
              sx={{ marginX: 'auto' }}
              type="submit"
            >
              Alterar Cartão
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </>
  );
}
