import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import useStore from '~/store';
import { MessageBubble } from '../Message';
import { NoIcon } from '../styles';
import { Message } from '../types';
import { YearOfManufacture } from '../YearOfManufacture';
import { container, containerDefault, containerMessage, inputStyle, messageAfter } from './styles';

interface Props {
  content?: Message['content'];
  author?: Message['author'];
  questionComponent?: Message['questionComponent'];
  Component?: Message['Component'];
}
interface Event {
  target: {
    name: string;
    value: string;
  };
}
interface CustomProps {
  onChange: (event: Event) => void;
  name: string;
}
interface NumberFormatProps {
  onChange: (event: Event) => void;
  name: string;
}

const NumberFormatCustom = forwardRef<NumberFormatProps, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={2}
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      fixedDecimalScale
      prefix=""
    />
  );
});

const OPTIONS = [
  {
    value: 1,
    label: 'Sim'
  },
  {
    value: 2,
    label: 'Não'
  }
];

export const EquipmentValue = ({ content, questionComponent }: Props) => {
  const { setEquipmentValue, setHasInvoice, setIsNewEquipment, quote } = useStore((store) => ({
    quote: store.quote,
    setEquipmentValue: store.setEquipmentValue,
    setHasInvoice: store.setHasInvoice,
    setIsNewEquipment: store.setIsNewEquipment
  }));
  const { equipmentValue, hasInvoice, isNewEquipment } = quote;
  const handleChangeHasInvoice = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '1';
    setHasInvoice(value);
  };
  const handleChangeIsNewEquipment = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '1';
    setIsNewEquipment(value);
  };

  const handleChangeEquipmentValue = (event: ChangeEvent<HTMLInputElement>) => {
    const equipmentValue = Number(event.target.value);
    setEquipmentValue(equipmentValue);
  };

  return (
    <Box sx={containerDefault}>
      <Box sx={container}>
        <Box sx={() => containerMessage()}>
          <TextField
            value={equipmentValue ? equipmentValue.toString() : ''}
            onChange={handleChangeEquipmentValue}
            name="equipmentValue"
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              startAdornment: (
                <>
                  <InputAdornment position="start">R$</InputAdornment>
                </>
              )
            }}
            variant="outlined"
            helperText="*Valor mínimo de R$ 1.000"
            sx={inputStyle}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={NoIcon(96)} />
        <MessageBubble content="Tempo de uso do equipamento?" />
      </Box>
      <Box sx={container}>
        <Box sx={() => containerMessage()}>
          <FormGroup>
            <FormGroup>
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-button-is-new-equipment"
                  value={isNewEquipment !== null && (isNewEquipment ? 1 : 2)}
                  onChange={handleChangeIsNewEquipment}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel
                      value="1"
                      label="Novo"
                      control={
                        <Radio
                          inputProps={{ 'aria-label': 'Novo' }}
                          checkedIcon={<CheckCircleIcon color="success" />}
                        />
                      }
                    />
                    <Typography
                      sx={{ color: '#969494', marginLeft: 1, fontSize: 12, display: 'flex', alignItems: 'center' }}
                    >
                      (máximo 30 dias da data da compra)
                    </Typography>
                  </Box>
                  <FormControlLabel
                    value="2"
                    label="Usado"
                    control={
                      <Radio inputProps={{ 'aria-label': 'Usado' }} checkedIcon={<CheckCircleIcon color="success" />} />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </FormGroup>
          <Box sx={messageAfter} />
        </Box>
        <YearOfManufacture />
        <Box sx={messageAfter} />
      </Box>{' '}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={NoIcon(96)} />
        <MessageBubble content="Tem nota fiscal ?" />
      </Box>
      <Box sx={container}>
        <Box sx={() => containerMessage()}>
          <FormGroup>
            <FormControl>
              <RadioGroup
                aria-labelledby="radio-button-has-invoice"
                value={hasInvoice !== null && (hasInvoice ? 1 : 2)}
                onChange={handleChangeHasInvoice}
              >
                {OPTIONS.map(({ value, label }) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    label={label}
                    control={
                      <Radio inputProps={{ 'aria-label': label }} checkedIcon={<CheckCircleIcon color="success" />} />
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </FormGroup>
          <Box sx={messageAfter} />
        </Box>
      </Box>
    </Box>
  );
};
