import { Container, Card, Box, CardContent, Typography, Divider } from '@mui/material';

import { cardContainer, cardHeader, container, containerForm } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchContract from './hooks/useFetchContract';
import { ContractInfo } from './components/ContractInfo';
import { Contract } from './types/contratResponse';
import { useState } from 'react';
import { CardAjust } from './components/CardAjust';
import { boolean } from 'yup';
import { center, description, title } from '~/pages/PaymentConfirm/styles';
import { AriOkIcon } from '~/assets/icons';
import logoFull from '../../../assets/images/logo-full.png';

export default function ConfigContract() {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const { contract, loading } = useFetchContract(contractId ?? '');
  const [cardEdit, setCardEdit] = useState(false);
  const [sucessRequest, setSucessRequest] = useState(false);

  if (loading) {
    return <>CARREGANDO OS DADOS</>;
  }

  if (sucessRequest) {
    return (
      <Container maxWidth="sm" sx={container}>
        <Box sx={[center]}>
          <AriOkIcon />
          <Typography variant="h5" sx={center} fontWeight="bold">
            Cartão de crédito alterado com sucesso
          </Typography>
        </Box>
        <Box sx={[center]}>
          <Typography color="primary" variant="h4" sx={title}>
            Parabêns
          </Typography>
        </Box>
        <Box sx={[center]}>
          <Typography color="primary" sx={title} textAlign="center">
            Sua proxima cobrança virá no novo cartão cadastrado
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={container}>
      <Card sx={cardContainer}>
        <CardContent>
          <Box sx={cardHeader}>
            <Box sx={[center]}>
              <img width={200} src={logoFull} />
            </Box>

            <Typography variant="h6" align="center" sx={{ fontSize: '1.6rem' }}>
              Configuração da assinatura
            </Typography>
          </Box>
          <Divider variant="middle" />
          {cardEdit ? (
            <CardAjust contractCode={contract?.contract_code ?? ''} setSucessRequest={setSucessRequest} />
          ) : (
            <ContractInfo contract={contract ?? ({} as Contract)} setCardEdit={setCardEdit} />
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
