import { List, ListItem, ListItemText, Divider, Typography, Button, Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import Colors from '~/styles/colors';
import {
  cardItemsContainer,
  cardListContainer,
  cardListItem,
  cardListItemTextPrimary,
  cardListItemTextSecondary,
  containerForm,
  contractValueContainer
} from '../styles';
import { Contract } from '../types/contratResponse';

interface ContractInfoProps {
  contract: Contract;
  setCardEdit: Dispatch<SetStateAction<boolean>>;
}

export function ContractInfo({ contract, setCardEdit }: ContractInfoProps) {
  return (
    <>
      <Box sx={cardItemsContainer}>
        <List sx={cardListContainer}>
          <ListItem sx={cardListItem}>
            <ListItemText
              primary="Certificado n"
              primaryTypographyProps={cardListItemTextPrimary}
              secondary={contract?.contract_code}
              secondaryTypographyProps={cardListItemTextSecondary}
            />
          </ListItem>
          <ListItem sx={cardListItem}>
            <ListItemText
              primary="Tipo"
              primaryTypographyProps={cardListItemTextPrimary}
              secondary={contract?.insured_item?.equipment_type}
              secondaryTypographyProps={cardListItemTextSecondary}
            />
          </ListItem>
          <ListItem sx={cardListItem}>
            <ListItemText
              primary="Modelo"
              primaryTypographyProps={cardListItemTextPrimary}
              secondary={contract?.insured_item?.model ?? '-'}
              secondaryTypographyProps={cardListItemTextSecondary}
            />
          </ListItem>
        </List>
        <List sx={cardListContainer}>
          <ListItem sx={cardListItem}>
            <ListItemText
              primary="Vigência"
              primaryTypographyProps={cardListItemTextPrimary}
              secondary={contract?.term_of_contract}
              secondaryTypographyProps={cardListItemTextSecondary}
            />
          </ListItem>
          <ListItem sx={cardListItem}>
            <ListItemText
              primary="Marca"
              primaryTypographyProps={cardListItemTextPrimary}
              secondary={contract?.insured_item?.manufacturer ?? '-'}
              secondaryTypographyProps={cardListItemTextSecondary}
            />
          </ListItem>
          <ListItem sx={cardListItem}>
            <ListItemText
              primary="Número de série"
              primaryTypographyProps={cardListItemTextPrimary}
              secondary={contract?.insured_item?.serial_number ?? '-'}
              secondaryTypographyProps={cardListItemTextSecondary}
            />
          </ListItem>
        </List>
      </Box>

      <Divider variant="middle" />
      <Box sx={contractValueContainer}>
        <Typography
          variant="h6"
          sx={{
            color: Colors.text?.primary || ''
          }}
        >
          Valor da assinatura:
        </Typography>
        <Typography
          sx={{
            color: Colors.primary?.main || ''
          }}
          variant="h6"
        >
          R${String(contract?.total).replace('.', ',')}
        </Typography>
      </Box>
      <Box sx={containerForm}>
        <Button variant="contained" sx={{ paddingX: 'auto' }} onClick={() => setCardEdit(true)}>
          Alterar dados do cartão de crédito
        </Button>
      </Box>
    </>
  );
}
