import Rollbar from 'rollbar';

const rollbar: any = new Rollbar({
  accessToken: '14a86f600e4344399af1bcb3276882d2',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV || 'development',
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  }
});

export default rollbar;
